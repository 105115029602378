import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import {
  registerAxiosInterceptor,
  registerFetchInterceptor,
  registerAxiosRequestInterceptor,
} from './utils/interceptors.js'

import './styles/base.scss'

ReactDOM.render(<App />, document.getElementById('root'))

registerAxiosInterceptor()
registerFetchInterceptor()
registerAxiosRequestInterceptor()
