import React, { useState, useEffect } from 'react'
import styles from './Invitation.module.scss'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import 'react-confirm-alert/src/react-confirm-alert.css'
import reg from '../../assets/images/Registration_Illustration.png'
import asterisk from '../../assets/images/Asterisk.png'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useGeoFetch } from '../../components/GeoProvider'
import { useAuthState } from '../../components/AuthProvider'

const Invitation = () => {
  const [message, setMessage] = useState('')

  const [IsError, setIsError] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [checked, setChecked] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const history = useHistory()
  const { user } = useAuthState()

  //Checking Admin access
  const { data: regData } = useGeoFetch(
    '',
    '',
    'CheckInvitationAdmin',
    user === null ? '' : user.email
  )

  useEffect(() => {    
    if (regData !== null && regData !== undefined) {
      if (regData.response_code === 200) {
        if (regData.response_data !== true) {
          history.push('/unauthorized')
        }
      } else {
        history.push('/unauthorized') ///ohid-login
      }
    }
  }, [regData])

  function handleChange() {
    setIsError(false)
    setSuccessMsg(false)
  }

  const SignupSchema = Yup.object().shape({
    emailid: Yup.string()
      .email('Invalid email id')
      .required('Email id required'),
  })

  const handleOnChange = () => {
    setChecked(!checked)
    if (checked) {
      //enable checkbox
      setIsEditable(false)
    } //disable checkbox
    else {
      setIsEditable(true)
      setIsError(false)
    }
  }

  const handleClickConfirm = (emailid) => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/sendinvitation?email=${emailid}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          requester: JSON.stringify(window.localStorage.getItem('requester')),
        },
        withCredentials: true,
        credentials: 'include',
      }
    )
      .then((response) => response.json())

      .then((data) => {
        if (data.response_code === 200) {
          setSuccessMsg(true)
          setMessage(data.response_data)
        } else if (data.response_code === 400) {
          //show failure message here
          setMessage(data.response_data)
          setIsError(true)
        } else if (data.response_code === 401) {
          //show failure message here
          setMessage('You are not authorised to access the application.')
          setIsError(true)
        } else {
          setMessage('Server error.Please try after sometime.')
          setIsError(true)
        }
      })
      .catch((err) => {
        // <ErrorMessage
        //   errorCode={'201'}
        //   errorMessage={
        //     'You can try reloading the page now or in a few minutes.'
        //   }
        //   errorSubject={'Unable to load the page'}
        //   errorDetails={err}
        // />
      })
  }

  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.maindivFormat}>
            <div style={{ width: '60%' }}>
              <img
                src={reg}
                alt="arrow-down"
                style={{ height: '550px', width: '100%', marginBottom: '-4px' }}
              ></img>
            </div>

            <div style={{ width: '30%', marginTop: '48px' }}>
              <div className={styles.formheader}>
                Welcome to the Infectious Disease Platform
              </div>

              <div className={styles.formheadertitle}>
                Please input email id to send invitation mail.{' '}
              </div>
              <div style={{ marginTop: '24px' }}></div>
              <Formik
                initialValues={{
                  emailid: '',
                }}
                validationSchema={isEditable !== true ? SignupSchema : null}
                onSubmit={(values) => {
                  // same shape as initial values
                  handleClickConfirm(values.emailid)
                }}
              >
                {({ errors }) => (
                  <Form>
                    <div style={{ marginTop: '24px' }}>
                      <div
                        className={
                          errors.emailid || IsError === true
                            ? styles.keyError
                            : styles.key
                        }
                      >
                        {' '}
                        Email Id{' '}
                        <img
                          src={asterisk}
                          alt="arrow-down"
                          style={{
                            height: '10px',
                            width: '8px',
                            marginLeft: '3px',
                          }}
                        ></img>
                      </div>

                      {IsError === true && (
                        <div
                          style={{ display: 'flex', marginBottom: '4px' }}
                          className={styles.errorMsg}
                        >
                          <span className={styles.infoImg}>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns=""
                            >
                              <path
                                d="M8 0.5C3.875 0.5 0.5 3.875 0.5 8C0.5 12.125 3.875 15.5 8 15.5C12.125 15.5 15.5 12.125 15.5 8C15.5 3.875 12.125 0.5 8 0.5ZM8 8.75C7.55 8.75 7.25 8.45 7.25 8V5C7.25 4.55 7.55 4.25 8 4.25C8.45 4.25 8.75 4.55 8.75 5V8C8.75 8.45 8.45 8.75 8 8.75ZM8.75 11.75H7.25V10.25H8.75V11.75Z"
                                fill="#C40000"
                              />
                            </svg>
                          </span>
                          <span className={styles.errorCaption}>{message}</span>
                        </div>
                      )}

                      {successMsg === true && (
                        <div style={{ display: 'flex', marginBottom: '4px' }}>
                          <span className={styles.successCaption}>
                            {message}
                          </span>
                        </div>
                      )}

                      <div>
                        {errors.emailid ? (
                          <div
                            style={{ display: 'flex', marginBottom: '4px' }}
                            className={styles.errorMsg}
                          >
                            <span className={styles.infoImg}>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns=""
                              >
                                <path
                                  d="M8 0.5C3.875 0.5 0.5 3.875 0.5 8C0.5 12.125 3.875 15.5 8 15.5C12.125 15.5 15.5 12.125 15.5 8C15.5 3.875 12.125 0.5 8 0.5ZM8 8.75C7.55 8.75 7.25 8.45 7.25 8V5C7.25 4.55 7.55 4.25 8 4.25C8.45 4.25 8.75 4.55 8.75 5V8C8.75 8.45 8.45 8.75 8 8.75ZM8.75 11.75H7.25V10.25H8.75V11.75Z"
                                  fill="#C40000"
                                />
                              </svg>
                            </span>
                            <span className={styles.errorCaption}>
                              {' '}
                              {errors.emailid}
                            </span>
                          </div>
                        ) : null}

                        <Field
                          disabled={isEditable === true ? true : false}
                          className={
                            errors.emailid || IsError === true
                              ? styles.txtError
                              : styles.txt
                          }
                          type="text"
                          id="emailid"
                          name="emailid"
                          onClick={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div style={{ display: 'flex', marginBottom: '4px' }}>
                        <input
                          type="checkbox"
                          id="all"
                          name="all"
                          value="all"
                          checked={checked}
                          onChange={handleOnChange}
                          className={styles.checkbox}
                        />
                        <span className={styles.mailText}>Mail To All </span>
                      </div>
                      <div class={styles.buttondiv}>
                        <button class={styles.button} type="submit">
                          Send Invitation
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}
export default Invitation
