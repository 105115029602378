import VectorDownGreen from '../assets/images/Vector-down-green.png'
import VectorDown from '../assets/images/Vector-down.png'
import VectorUp from '../assets/images/Vector-up.png'
import VectorUpRed from '../assets/images/Vector-up-red.png'
import Info from '../assets/images/Icon-Info-Circle-Hollow.png'
import reg from '../assets/images/Registration_Illustration.png'
import close from '../assets/images/close.png'
import loctionIcon from '../assets/images/locaton_pin.png'
import phoneIcon from '../assets/images/phone_icon.png'
import globeIcon from '../assets/images/globe_icon.png'
import expandless from '../assets/images/expandless.svg'
import expandmore from '../assets/images/expandmore.svg'
import righticon from '../assets/images/icon/right-icon.png'
import arrowIconLeft from '../assets/images/icon/arrow-icon-left.png'
import medicalFactors from '../assets/images/icon/medical_factors.png'
import rectangle_barchart from '../assets/images/icon/rectangle_barchart.png'
import line_barchart from '../assets/images/icon/line_barchart.png'
const getVectorImage = (istrue, height = '16px', width = '14px') => {
  if (istrue === 'down') {
    return (
      <img
        src={VectorDown}
        alt="arrow-down"
        style={{ height: height, width: width, marginBottom: '-3px' }}
      ></img>
    )
  } else if (istrue === 'up') {
    return (
      <img
        src={VectorUp}
        alt="arrow-up"
        style={{ height: height, width: width, marginBottom: '-3px' }}
      ></img>
    )
  } else if (istrue === 'expandmore') {
    return (
      <img
        src={expandmore}
        alt="expand more"
        style={{ height: height, width: width }}
      ></img>
    )
  } else if (istrue === 'expandless') {
    return (
      <img
        src={expandless}
        alt="expand less"
        style={{ height: height, width: width }}
      ></img>
    )
  } else if (istrue === 'illustration') {
    return (
      <img
        src={reg}
        alt="Login Illustration"
        style={{ height: '550px', width: '100%' }}
      ></img>
    )
  } else if (istrue === 'location') {
    return (
      <img
        src={loctionIcon}
        alt="location"
        style={{ height: '19px', width: '16px', marginBottom: '-6px' }}
      ></img>
    )
  } else if (istrue === 'phone') {
    return (
      <img
        src={phoneIcon}
        alt="phoneIcon"
        style={{ height: '17.96px', width: '17.96px' }}
      ></img>
    )
  } else if (istrue === 'global') {
    return (
      <img
        src={globeIcon}
        alt="globeIcon"
        style={{ height: '20px', width: '20px' }}
      ></img>
    )
  } else if (istrue === 'close') {
    return (
      <img
        src={close}
        alt="close"
        style={{ height: height, width: width }}
      ></img>
    )
  } else if (istrue === 'Info') {
    return (
      <img
        src={Info}
        alt="Info"
        style={{ height: width, width: height, marginBottom: '-4px' }}
      ></img>
    )
  } else if (istrue === 'righticon') {
    return (
      <img
        src={righticon}
        alt="Selected"
        style={{ height: width, width: height }}
      ></img>
    )
  }
  else if(istrue === 'arrowleft'){
    return (
      <img
        src={arrowIconLeft}
        alt="Arrow left"
        style={{ height: width, width: height }}
      ></img>
    )
  } else if(istrue === 'medical_factors'){
    return (
      <img
        src={medicalFactors}
        alt="Factors"
        style={{ height: width, width: height }}
      ></img>
    )
  } 
  else if(istrue === 'rectangle_barchart'){
    return (
      <img
        src={rectangle_barchart}
        alt="rectangle barchart"
        style={{ height: width, width: height }}
      ></img>
    )
  } 
  else if(istrue === 'line_barchart'){
    return (
      <img
        src={line_barchart}
        alt="line barchart"
        style={{ height: width, width: height }}
      ></img>
    )
  } 
  else if (istrue) {
    return (
      <img
        src={VectorUpRed}
        alt="arrow-up"
        style={{ height: height, width: width, marginBottom: '-4px' }}
      ></img>
    )
  } else if (!istrue) {
    return (
      <img
        src={VectorDownGreen}
        alt="arrow-down"
        style={{ height: height, width: width, marginBottom: '-4px' }}
      ></img>
    )
  }
}

export default getVectorImage
