import React, { useState, useEffect } from 'react'
import {  useHistory } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import styles from './Registration.module.scss'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import 'react-confirm-alert/src/react-confirm-alert.css'
import reg from '../../assets/images/Registration_Illustration.png'
import asterisk from '../../assets/images/Asterisk.png'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
const Registration = () => {
  //const { user } = useAuthState()
  const [message, setMessage] = useState('')
  const cookies = document.cookie
  let cookie = require('cookie')
  let cookieObjects = cookie.parse(cookies)
  const idpCookie = cookieObjects['userReg']
  const [IsConfirm, setIsConfirm] = useState(true)
  const [IsError, setIsError] = useState(false)
  const [Company, setCompany] = useState(false)
  const history = useHistory()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [geterrorOccured, seterrorOccured] = useState(false)

  if(idpCookie===undefined || idpCookie===null)
  {
    ErrorLogs({
      message_type: 'Activity',
      error_details: `Registration page can not be accessed directly`,
      user: '',
    })
 
}

  const { username, givenName, familyName, email, phone, uuid } =
    jwtDecode(idpCookie)
  const userData = {
    username: username,
    firstName: givenName,
    lastName: familyName,
    email: email,
    phone: phone === undefined || phone === '' ? 'NA' : phone,
    userid: uuid,
  }

  //For getting company name
  const { data: regData } = useGeoFetch('', '', 'getcompany', email)

  useEffect(() => {    
    if (regData !== null && regData !== undefined) {
      if (regData.response_code === 200 && regData.response_data.length > 0 ) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: `Registration page landing success for lob:${regData.response_data[0].lob}`,
          user: userData,
        }) 
        if (regData.response_data[0].lob !=null)
        {
          setCompany(regData.response_data[0].lob.toUpperCase())
        }       
        else 
        {
          setErrorCode('401')
          setErrorMessage('You are not authorised to access the page')
          setErrorSubject('Unauthorised')
          seterrorOccured(true)
        }
      } else {
        setCompany('')
        setErrorCode('401')
          setErrorMessage('You are not authorised to access the page')
          setErrorSubject('Unauthorised')
          seterrorOccured(true)
      }
    }
  }, [regData])


  function handleChange() {
    setIsError(false)
  }

  const SignupSchema = Yup.object().shape({
    accesskey: Yup.string()
      .min(10, 'Access key is too Short')
      .max(60, 'Access key is too long')
      .required('Access key is required'),
  })

  const handleClickConfirm = (accesskey) => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/validateuserkey?email=${
        userData.email
      } &key=${accesskey.trimEnd()} &uuid=${userData.userid}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          requester: JSON.stringify(window.localStorage.getItem('requester')),
        },
        withCredentials: true,
        credentials: 'include',
      }
    )
      .then((response) => response.json())

      .then((data) => {
        if (data.response_code === 200) {
          if (data.response_data === true) {
            //send to login page
            setMessage('')
            setIsConfirm(false)
          } else {
            //show failure message here
            //seterrorColor('#C40000');
            setIsError(true)
            setMessage(data.response_message)
          }
        } else if (data.response_code === 400) {
          ErrorLogs({
            message_type: 'Activity',
            error_details: 'Registration data is not in correct format',
            user: userData,
          })
          //show failure message here
          //setMessage('The input data is not in correct format.')
          setMessage(data.response_message)
          setIsError(true)
        } else if (data.response_code === 401) {
          //show failure message here
          ErrorLogs({
            message_type: 'Activity',
            error_details:
              'Registration You are not authorised to access the application',
            user: userData,
          })

          setMessage('You are not authorised to access the application.')
          setIsError(true)
        } else {
          ErrorLogs({
            message_type: 'Activity',
            error_details:
              'Registration Server error.Please try after sometime.',
            user: userData,
          })

          setMessage('Server error.Please try after sometime.')
          setIsError(true)
        }
      })
      .catch((err) => {
        // <ErrorMessage
        //   errorCode={'201'}
        //   errorMessage={
        //     'You can try reloading the page now or in a few minutes.'
        //   }
        //   errorSubject={'Unable to load the page'}
        //   errorDetails={err}
        // />
      })
  }
  
  return (
    <Layout>
     
        {geterrorOccured ? 
         ( <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />) :
      (
        <Content>
<div className={styles.base}>
          <div className={styles.maindivFormat}>
            <div style={{ width: '60%' }}>
              <img
                src={reg}
                alt="arrow-down"
                style={{ height: '550px', width: '100%', marginBottom: '-4px' }}
              ></img>
            </div>

            <div style={{ width: '31%', marginTop: '48px' }}>
              <div className={styles.formheader}>
                Welcome to the Infectious Disease Platform
              </div>

              {IsConfirm === true && (
                <>
                  <div className={styles.formheadertitle}>
                    Please input your access key to continue.{' '}
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div className={styles.user}>Username:</div>{' '}
                      <div className={styles.userval}>
                        {userData.username.toUpperCase()}
                      </div>
                    </div>
                    <hr />
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div className={styles.user}>First Name:</div>{' '}
                      <div className={styles.userval}>
                        {userData.firstName.toUpperCase()}
                      </div>
                    </div>
                    <hr />
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div className={styles.user}>Email:</div>{' '}
                      <div className={styles.userval}>
                        {userData.email.toUpperCase()}
                      </div>
                    </div>
                    <hr />
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div className={styles.user}>Phone:</div>{' '}
                      <div className={styles.userval}>{userData.phone}</div>
                    </div>
                    <hr />
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div className={styles.user}>Company:</div>{' '}
                      <div className={styles.userval}>
                        <span class={styles.lobFormat}>{Company}</span>
                      </div>
                    </div>
                    <hr />
                  </div>
                  <Formik
                    initialValues={{
                      accesskey: '',
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values) => {
                      // same shape as initial values
                      handleClickConfirm(values.accesskey)
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div style={{ marginTop: '24px' }}>
                          <div
                            className={
                              errors.accesskey || IsError === true
                                ? styles.keyError
                                : styles.key
                            }
                          >
                            {' '}
                            Access key{' '}
                            <img
                              src={asterisk}
                              alt="arrow-down"
                              style={{
                                height: '10px',
                                width: '8px',
                                marginLeft: '3px',
                              }}
                            ></img>
                          </div>

                          {IsError === true && (
                            <div
                              style={{ display: 'flex', marginBottom: '4px' }}
                              className={styles.errorMsg}
                            >
                              <span className={styles.infoImg}>
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns=""
                                >
                                  <path
                                    d="M8 0.5C3.875 0.5 0.5 3.875 0.5 8C0.5 12.125 3.875 15.5 8 15.5C12.125 15.5 15.5 12.125 15.5 8C15.5 3.875 12.125 0.5 8 0.5ZM8 8.75C7.55 8.75 7.25 8.45 7.25 8V5C7.25 4.55 7.55 4.25 8 4.25C8.45 4.25 8.75 4.55 8.75 5V8C8.75 8.45 8.45 8.75 8 8.75ZM8.75 11.75H7.25V10.25H8.75V11.75Z"
                                    fill="#C40000"
                                  />
                                </svg>
                              </span>
                              <span className={styles.errorCaption}>
                                {message}
                              </span>
                            </div>
                          )}
                          <div>
                            {errors.accesskey ? (
                              <div
                                style={{ display: 'flex', marginBottom: '4px' }}
                                className={styles.errorMsg}
                              >
                                <span className={styles.infoImg}>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns=""
                                  >
                                    <path
                                      d="M8 0.5C3.875 0.5 0.5 3.875 0.5 8C0.5 12.125 3.875 15.5 8 15.5C12.125 15.5 15.5 12.125 15.5 8C15.5 3.875 12.125 0.5 8 0.5ZM8 8.75C7.55 8.75 7.25 8.45 7.25 8V5C7.25 4.55 7.55 4.25 8 4.25C8.45 4.25 8.75 4.55 8.75 5V8C8.75 8.45 8.45 8.75 8 8.75ZM8.75 11.75H7.25V10.25H8.75V11.75Z"
                                      fill="#C40000"
                                    />
                                  </svg>
                                </span>
                                <span className={styles.errorCaption}>
                                  {' '}
                                  {errors.accesskey}
                                </span>
                              </div>
                            ) : null}
                            <Field
                              className={
                                errors.accesskey || IsError === true
                                  ? styles.txtError
                                  : styles.txt
                              }
                              type="text"
                              id="accesskey"
                              name="accesskey"
                              maxLength={60}
                              onClick={handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div class={styles.buttondiv}>
                            {/* <div class={styles.button} onClick={handleClickConfirm}> Submit </div> */}
                            <button class={styles.button} type="submit">
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
              {IsConfirm !== true && (
                <>
                  <div>
                    <div className={styles.confirmText}>
                      <p>
                        <span style={{ marginLeft: '1px' }}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM7.3 14.3L3.7 10.7C3.3 10.3 3.3 9.7 3.7 9.3C4.1 8.9 4.7 8.9 5.1 9.3L8 12.2L14.9 5.3C15.3 4.9 15.9 4.9 16.3 5.3C16.7 5.7 16.7 6.3 16.3 6.7L8.7 14.3C8.3 14.7 7.7 14.7 7.3 14.3Z"
                              fill="#007000"
                            />
                          </svg>
                        </span>
                        <span style={{ margin: '4px' }}>
                          The access key was successfully submitted. You can now
                          sign in to the application with your One Healthcare ID by
                          selecting the Sign In button below.
                        </span>
                      </p>
                    </div>
                    <div className={styles.buttondivId}>
                      {' '}
                      <div
                        className={styles.buttonId}
                        onClick={() => history.push('/ohid-login')}
                      >
                        {' '}
                        One Healthcare ID Sign In{' '}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        </Content>
      )
        } 
        
     
    </Layout>
  )
      }
export default Registration
